import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "クライアント認証と Path Based Routing が必要なサーバを AWS で構築（後編：App 層）",
  "date": "2017-08-24T03:00:00.000Z",
  "slug": "entry/2017/08/24/120000_02",
  "tags": ["medley"],
  "hero": "./2017_08_24_02.png",
  "heroAlt": "routing"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`今回の内容について`}</h1>
    <p>{`メドレー開発本部の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/57252"
      }}>{`田中`}</a>{`です。
先日、Proxy 層を Elastic Beanstalk 上の Nginx で、App 層を EC2 インスタンスで構築する機会がありました。ここだけ見るととても普通に見えますが、制約があることで苦労した点もあり（`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_01"
      }}>{`前編参照`}</a>{`）、制約を乗り越えるための工夫も含めてお話できる限り共有させていただきます。
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_01"
      }}>{`前編では`}</a>{`Proxy 層の構成として、主に Nginx を使用した Path Based Routing 周りについてのお話でした。後編では App 層で使用した EC2、 Systems Manager パラメータストアあたりについて共有いたします。`}</p>
    <h1>{`App 層の構成`}</h1>
    <p>{`App 層の方針や構築の流れ等をまとめると以下の通りです。`}</p>
    <ul>
      <li parentName="ul">{`ゴールデンイメージとして OS 設定やサーバアプリケーションをインストールした image（AMI）を作成しておく`}</li>
      <li parentName="ul">{`上記の AMI を元に、クライアント毎に EC2 インスタンスを作成する`}
        <ul parentName="li">
          <li parentName="ul">{`インスタンス作成時に必要な Tag の値や環境変数を設定しておく`}</li>
          <li parentName="ul">{`環境変数はパラメータストアに登録`}</li>
        </ul>
      </li>
      <li parentName="ul">{`EC2 インスタンス起動時に、クライアントに応じた Tag や環境変数をもとにサーバアプリケーションのセットアップを行う`}</li>
      <li parentName="ul">{`自身の内部 IP と Tag に設定したクライアント識別 ID を元に Route53 の PrivateDNS に登録する`}</li>
    </ul>
    <p>{`それでは、それぞれの詳細について説明していきたいと思います。`}</p>
    <h2>{`AMI 作成`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.packer.io/"
      }}>{`Packer`}</a>{`を使用して各インスタンス共通となる AMI を作成します。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`provisioners`}</code>{`で指定した構築用スクリプトで OS 設定や必要ライブラリ、またメインとなるサーバアプリケーションをインストールします。また、cloud-init を使用して初回起動時に動かすスクリプト類もコピーしておきます。`}</p>
    <p>{`なお、cloud-init から実行するスクリプトは Git や S3 などから動的に取得する方法もありますが、さほどスクリプトの内容に変更は発生しない点と、内容的に変更ある場合は image 再作成がどちらにしても必要になりそうだったので割り切って image 内に含めることにしています。`}</p>
    <p>{`作成した packer.json の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`provisioners`}</code>{`部分を抜粋するとこのような感じになります（説明コメント部分は実際には記載していません）`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"provisioners"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    -- type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` shell として、構築用スクリプト指定。ビルド時に実行される
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"shell"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"scripts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"scripts/provision.sh"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    -- type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` file でインスタンス起動時に実行させるスクリプト群をコピー
    -- これらのスクリプトは cloud-init から実行される（cloud-init の設定は別途インスタンス作成時に行っている）
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"file"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"./scripts"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/home/hoge"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    -- 上記のスクリプトに対して実行権限付与
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"shell"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"inline"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"chmod +x /home/hoge/scripts/*"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`packer build`}</code>{` でビルドした image が AWS に今回の共通で使用する AMI として登録されます`}</p>
    <h2>{`EC2 インスタンス作成`}</h2>
    <p>{`作成した AMI を元に、クライアントごとのインスタンスを作成します。なお、インスタンス作成は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Terraform`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CloudFormation`}</code>{`などは使わず、AWS CLI を利用したスクリプトを作成して実行しています。`}</p>
    <p>{`インスタンス作成スクリプトはこのような流れの処理となります。`}</p>
    <ul>
      <li parentName="ul">{`引数でクライアント識別 ID やその他サーバアプリケーションセットアップに必要となる環境変数を指定`}</li>
      <li parentName="ul">{`AWS CLI で EC2 インスタンス作成`}</li>
      <li parentName="ul">{`引数で指定された環境変数を AWS CLI でパラメータストアに登録`}</li>
    </ul>
    <h3>{`インスタンス作成`}</h3>
    <p>{`以下のように、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aws ec2 run-instances`}</code>{` コマンドを使用し、Tag にクライアント識別 ID を指定して作成しています。
ここで指定したクライアント識別 ID を元にパラメータストアから自分用の環境変数を登録/取得したり、Private DNS 用のドメインに使用します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`aws ec2 run-instances `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --image-id `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${AMI_ID}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --key-name `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${KEY_NAME}`}</span>{`
  --region `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${REGION}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --subnet-id `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${SUBNET_ID}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --security-group-ids `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${SECURITY_GROUP}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --user-data file://`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${USER_DATA}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --instance-type `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${INSTANCE_TYPE}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --tag-specifications `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ResourceType=instance,Tags=[{Key=ClientId,Value=`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\${CLIENT_ID}`}</span>{`}]"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --iam-instance-profile `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Arn=`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\${SERVICE_ROLE}`}</span>{`"`}</span>{`

`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`user-data`}</code>{` には初回起動時に実行したいスクリプト（Packer でビルド時にコピーしておいたスクリプト）を指定しているだけとなります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`
/home/hoge/scripts/bootstrap.sh`}</code></pre></div>
    <h3>{`パラメータストアに環境変数登録`}</h3>
    <p>{`使用する環境変数は、Key は共通ですが値がクライアントによって異なります。そのため、HOGE という Key を使用する場合、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<クライアント識別 ID>.HOGE`}</code>{` という形式でパラメータストアに登録しています。`}</p>
    <p>{`（注. パラメータストアに`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/about-aws/whats-new/2017/06/amazon-ec2-systems-manager-adds-hierarchy-tagging-and-notification-support-for-parameter-store/"
      }}>{`階層やタグ付けがサポートされた`}</a>{`らしく、このあたりの構成は今後見直す予定です）`}</p>
    <p>{`登録は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aws ssm put-parameter`}</code>{` を実行します`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`aws ssm put-parameter `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --name `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${KEY}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --value `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${VALUE}`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --type `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${PARAMETER_TYPE}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# String、SecureString など`}</span>{`
  --overwrite`}</code></pre></div>
    <p>{`これでクライアントごとの EC2 インスタンスが作成、起動されます。次にインスタンス起動時の流れについてです。`}</p>
    <h2>{`EC2 インスタンス起動`}</h2>
    <p>{`起動時は、初回起動と毎回起動でそれぞれ以下のような処理を行います。`}</p>
    <ul>
      <li parentName="ul">{`初回: パラメータストアから自身に関連する環境変数を取得し、サーバアプリケーションのセットアップ`}</li>
      <li parentName="ul">{`毎回: 自身の内部 IP を Route53 の Private DNS に登録/更新`}</li>
    </ul>
    <p>{`内部 IP は固定しておらず起動時に割り振られるため、毎回更新するようにしています。`}</p>
    <p>{`それではそれぞれの内容について見ていきます。`}</p>
    <h3>{`パラメータストアから環境変数取得`}</h3>
    <p>{`登録時の内容で記載しましたが、環境変数は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<クライアント識別 ID>.HOGE`}</code>{`という形式で登録しています。そのため、まずは自身のクライアント識別 ID を判定した後に必要な環境変数を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aws ssm get-parameters`}</code>{`で取得します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 自身のインスタンス ID をメタデータから取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`INSTANCE_ID`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`curl`}</span>{` -s https://169.254.169.254/latest/meta-data/instance-id`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# クライアント識別 ID をインスタンス作成時に指定した Tag から取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# (describe-instances の filter に自身のインスタンス ID を指定)`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`CLIENT_ID_TAG`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`aws ec2 describe-instances `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --region`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`$`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`REGION`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --filters `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Name=instance-id,Values=`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\${INSTANCE_ID}`}</span>{`"`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'.Reservations[].Instances[].Tags[] | select(.Key == "ClientId").Value'`}</span>{`
`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 環境変数を取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# タイプを SecureString にしている変数もあるため、一律 --with-decryption オプションを指定している`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`HOGE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`aws ssm get-parameters `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --name `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\${CLIENT_ID_TAG}`}</span>{`.HOGE"`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --with-decryption --region $`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`REGION`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`".Parameters[].Value"`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`


`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`HOGE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`\${HOGE}`}</span></code></pre></div>
    <h3>{`内部 IP を Private DNS に登録`}</h3>
    <p>{`最後に、Proxy 層から Private DNS で名前解決できるように自身の IP を Route 53 に登録してやります。`}</p>
    <p>{`なお、Route53 には事前に対象の Hosted Zone を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Private Hosted Zone for Amazon VPC`}</code>{`タイプとして登録しておきます。ここでは例として Domain Name を local とします。`}</p>
    <p>{`EC2 インスタンスから登録される RecordSet は以下の形式とします。`}</p>
    <ul>
      <li parentName="ul">{`Name: <クライアント識別 ID>.local`}</li>
      <li parentName="ul">{`Type: CNAME`}</li>
      <li parentName="ul">{`Value: EC2 インスタンスの内部 IP`}</li>
    </ul>
    <p>{`これらを行うスクリプト例は以下となります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 内部 IP を取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# (describe-instances の filter に自身のインスタンス ID を指定)`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`PRIVATE_IP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`aws ec2 describe-instances `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --region`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`$`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`REGION`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --filters `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Name=instance-id,Values=`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\${INSTANCE_ID}`}</span>{`"`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'.Reservations[].Instances[].PrivateIpAddress'`}</span>{`
`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Route53 の登録先 Hosted Zone ID を取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SEARCH_KEY は今回の例でいうと 'local.' になります`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`HOSTED_ZONE_ID`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`aws route53 list-hosted-zones `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  --region`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`$`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`REGION`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`
  `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` jq -r `}<span parentName="span" {...{
              "className": "token string"
            }}>{`".HostedZones[] | select(.Name == `}<span parentName="span" {...{
                "className": "token entity",
                "title": "\\\""
              }}>{`\\"`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`\${SEARCH_KEY}`}</span><span parentName="span" {...{
                "className": "token entity",
                "title": "\\\""
              }}>{`\\"`}</span>{`).Id"`}</span>{`
`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# この後の登録コマンドで指定するための定義ファイル`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 毎起動時の登録用（IP が変わるため）に、Action には 'UPSERT' を指定`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`RECORDSET_FILE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/tmp/create_recordset.json"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`EOT`}<span parentName="span" {...{
              "className": "token bash punctuation"
            }}>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span>{` `}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\${RECORDSET_FILE}`}</span></span>{`
{
  "Changes": [
    {
      "Action": "UPSERT",
      "ResourceRecordSet": {
        "Name": "<クライアント識別 ID>.local",
        "Type": "CNAME",
        "TTL": 300,
        "ResourceRecords": [
          {
            "Value": "`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\${PRIVATE_IP}`}</span>{`"
          }
        ]
      }
    }
  ]
}
EOT`}</span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 作成した定義ファイルを指定し、Route53 に登録`}</span>{`
aws route53 change-resource-record-sets  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --hosted-zone-id `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${HOSTED_ZONE_ID}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --change-batch file:///`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${RECORDSET_FILE}`}</span></code></pre></div>
    <p>{`実行するステップはやや多いですが、このような構成をとることで VPC 内ではドメイン指定でのアクセスが可能となるため、IP を意識する必要がなくなるため柔軟な構成になるかと思います。`}</p>
    <h1>{`今回のまとめ`}</h1>
    <p>{`いまさらインスタンス立てるとかめんどくさいなぁ、、、とか思いながら色々調べて構築しましたが、EC2 まわりのサービスも増えてるんだなぁ、なんて感じました（特にパラメータストアはとても便利）`}</p>
    <p>{`パラメータストア以外にも Systems Manager には Run Command や Patch Manager など EC2 インスタンスを管理する上でとても便利な仕組みが揃っていますのでこのあたりも導入していきたいと思います。`}</p>
    <p>{`余談ですが、Systems Manager の存在は re:Invent 2016 で発表された時から名前だけは知ってましたが、今回の対応するまでずっとオンプレ専用のサービスだと勘違いしてて記憶から消えかけていました。。。`}</p>
    <h1>{`最後に`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_01"
      }}>{`前編を Proxy 層`}</a>{`（Nginx）、後編を App 層（EC2）について書かせていただきましたがいかかだったでしょうか。
そもそもの要件自体がけっこう特殊だったりもするので、なんでこんな構成に？みたいなとこもあるかも知れませんが、どなたかの参考になれば幸いです。もう少し聞いてみたい、というかたは wantedly の「`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`話を聞いてみたい`}</a>{`」ボタンからどうぞ。`}</p>
    <p>{`※前編をあらためて読みたい方はこちらからどうぞ
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_01"
      }}>{`https://developer.medley.jp/entry/2017/08/24/120000_01`}</a></p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      